.invoice_mcn {

}

.invoice_mcn.mcn_list {
  table {
    thead {
      .th-s {
        width: 130px;
      }

      .th-m {
        width: 180px;
      }

      .th-l {
        width: 240px;
      }

      .th-date {
        width: 118px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

.invoice_mcn.mcn_detail {
  .card-title {
    font-size: 24px;
    font-weight: 700;
  }

  .mcn-info {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .data-wrap {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex: 1;
      max-width: calc(50% - 20px - 0.25rem);

      .key,
      .value {
        font-size: 16px;
        font-weight: bold;
      }

      .key {
        min-width: fit-content;
      }

      .value {
        overflow: hidden;
      }
    }

    .mcn-desc {
      width: 100%;

      label {
        font-weight: bold;
      }

      .description-card {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .mcn-info-section,
  .mcn-modify-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mcn-info-section .mcn-info {
    .data-wrap {
      align-items: self-start;
    }

    .mcn-desc .description-card .description {
      margin: 16px;
      padding-right: 5px;
      overflow-y: scroll;
      height: 118px;
    }
  }

  .mcn-modify-section .mcn-info {
    label {
      font-weight: bold;
    }

    &.company-name {
      .form-group {
        display: flex;
        width: 100%;
        gap: 20px;

        label {
          min-width: fit-content;
          font-size: 20px;
          margin-bottom: 0;
          align-self: center;
        }
      }
    }

    .data-wrap {
      align-items: center;
    }

    .form-group {
      margin-bottom: 0;
      .col {
        margin-bottom: 0;
      }
    }

    .select-wrap {
      display: flex;
      gap: 10px;

      .select-country {
        width: 100%;
      }

      .select-btn-wrap {
        min-width: fit-content;
      }
    }

    .select-list {
      display: flex;
      gap: 4px;
      flex-flow: row wrap;
      margin-top: 5px;

      & > span {
        cursor: pointer;
      }

      .badge {
        border-radius: 10px;
        padding: 4px 8px;
      }
    }

    .description-card {
      padding: 16px;
      textarea {
        resize: none;
        outline: none;
        border: none;
        font-size: 16px;
        width: 100%;
        height: 118px;
      }
    }
  }

  .mcn-modify-section .btn-wrap {
    display: flex;
    justify-content: end;
    gap: 10px;
  }

  .mcn-modify-section .mcn-info.grid2 {
    & > div {
      width: calc(50% - 20px - 0.25rem);
    }
  }
}
