.influencer-invoice-modal {
  .prev-name {
    font-size: 14px;
    margin-left: 6px;
    word-break: break-all;
  }

  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #dee2e6;

    .add-btn {
      min-width: 92px;
      padding: 0.25rem 0.5rem;
    }
  }

  .modal-sub-title {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 10px;
    border-bottom: 1.5px solid black;
  }

  .modal-sub-title.fold {
    display: flex;
    justify-content: space-between;
  }

  .fold-btn {
    width: 10px;
    height: 10px;
    border: 2.5px solid #000;
    border-top: 0;
    border-right: 0;
    transform: translate(-6px, 50%) rotate(-45deg);
    cursor: pointer;
  }

  .fold-btn.show {
    transform: translate(-6px, 100%) rotate(135deg);
  }

  .modal-scroll {
    overflow-y: scroll;
  }

  .modal-body {
    .search-wrap {
      gap: 8px;
      margin-bottom: 0.75rem;

      button {
        min-width: 70px;
      }
    }

    .input-wrap {
      display: flex;
      flex-direction: column;
    }

    .combination-inputs {
      margin-bottom: 1rem;

      .combination-input {
        .desc {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          width: 50px;
          min-width: 50px;
          height: 38px;
          border: 1px solid #ced4da;
          border-right: 0;
          border-radius: 0.25rem 0 0 0.25rem;
        }
        input {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }

      .combination-input + .combination-input {
        margin-top: 0.5rem;
      }
    }

    .table-wrap {
      table {
        thead {
          .th-check-box {
            width: 40px;
          }
        }

        tbody {
          .form-check-input {
            margin-left: 0;
            margin-top: 0;
          }
          .product-name {
            overflow: unset;
            text-overflow: unset;
            white-space: normal;
            word-break: break-all;
          }

          .selected-table {
            cursor: pointer;
            td {
              padding: 0.5rem;
            }
          }
          .selected-table:hover,
          .selected-table.active {
            border: 2px solid #8200FF;
            border-top: 3px solid #8200FF;
          }
        }
      }
    }
  }
}

.influencer-invoice-modal.find-invoice-contractor-modal {
  .tab-dashboard-wrapper.card {
    border: unset;

    .tab-wrap {
      border: 1px solid #000000;
      .tab {
        padding: 10px 0 !important;
      }
    }

    .tab-body {
      padding: 12px 0 0 !important;
      .search-wrap {
        display: flex;
      }

      .find-invoice-mcn-table-wrap,
      .find-invoice-contractor-table-wrap {
        table {
          td,
          th {
            font-size: 13.6px;
          }

          thead {
            .th-s {
              width: 110px;
            }
            .th-m {
              width: 170px;
            }
          }
        }
      }
    }
  }
}


.influencer-invoice-modal.insert-modify-contractor-modal {
  .file-wrap {
    margin-bottom: 1rem;
    .file-input {
      display: none;
    }
    .btn-wrap {
      display: flex;
      gap: 10px;
    }
  }
  .select-mcn {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 1s ease-out, opacity 0.5s ease-out;
  }
  .select-mcn.show {
    max-height: unset;
    opacity: 1;
  }

  .mcn-company-table {
    .search-wrap {
      display: flex;
      padding: 3px 3px 0;
    }
    table {
      thead {
        th {
          width: 50%;
        }
      }
    }
  }
}

.influencer-invoice-modal.find-invoice-bank-account-modal {
  table {
    thead {
      .th-s {
        width: 145px;
      }
      .th-m {
        width: 165px;
      }
    }
  }
}