.invoice-contractor.contractor-influencer-table {
  .header-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .table-title {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .scroll-table-wrap {
    table {
      thead {
        .th-s {
          width: 100px;
        }
        .th-m {
          width: 115px;
        }
        .th-link {
          width: 85px;
        }
        .th-btn {
          width: 92px;
        }
      }
      tbody {
        .influencer-wrap {
          display: flex;
          .image-wrap {
            margin-right: 0.6rem;
            img {
              max-width: 40px;
              min-width: 40px;
              max-height: 40px;
              min-height: 40px;
              border-radius: 50%;
            }
          }

          .image-wrap.outline {
            border-radius: 50%;
            border: 1px solid #999999;
            img {
              max-width: 38px;
              min-width: 38px;
              max-height: 38px;
              min-height: 38px;
              border-radius: 50%;
              padding: 2px;
            }
          }

          .nickname-wrap {
            display: flex;
            flex-direction: column;
            .name-text {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
        .td-btn {
          padding: 0.25rem 0.75rem;
        }

        .link {
          text-decoration: underline;
          color: #6E97FF;
          cursor: pointer;
        }

        .link.disabled {
          cursor: unset;
          color: #999999;
        }
      }
    }
  }
}

.invoice-contractor.contractor-influencer-modal {
  .search-wrap {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    button {
      min-width: fit-content;
    }
  }

  table {
    thead {
      .th-s {
        width: 52px;
      }
      .th-m {
        width: 100px;
      }
    }
    tbody {
      .form-check-input {
        margin: 0;
      }
    }
  }
}