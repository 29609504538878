.invoice-manage-brand {
  table {
    tbody {
      .td-btn {
        padding: 0.25rem 0.75rem;
      }
    }
  }
}

.invoice-manage-brand.list {
  .btn-wrap {
    display: flex;
    gap: 16px;
    justify-content: end;
  }

  .table-wrap {
    .card {
      padding-bottom: 20px;
    }

    table {
      thead {
        .th-l {
          width: 280px;
        }
        .th-m {
          width: 105px;
        }
        .th-s {
          width: 70px;
        }
        .th-btn {
          width: 92px;
        }
        .th-date {
          width: 118px;
        }
      }

      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
}

.invoice-manage-brand.brand-detail,
.invoice-manage-brand.product-detail {
  .page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .change-data button {
    font-size: 18px;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
  }

  .link-table {
    table {
      thead {
        .th-platform {
          width: 150px;
        }
        .th-btn {
          width: 105px;
        }
        .th-date {
          width: 130px;
        }
      }
    }
  }

  .dashboard-invoice-section {
    .dashboard-card {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .invoice-summary-wrap {
      display: flex;
      gap: 16px;

      .summary-card {
        flex: 1;
        padding: 10px;

        .summary-title,
        .summary-count {
          font-size: 14px;
          font-weight: bold;
        }

        .summary-count {
          margin-top: 16px;
          text-align: end;
        }
      }
    }
    .invoice-product-series-section {
      .series-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
      }

      .series-tip {
        margin-left: 8px;
        padding: 0 5.51px;
        border: 1px solid #8200FF;
        border-radius: 50%;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        color: #8200FF;
      }

      .series-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .series-title-wrap {
          display: flex;
          height: 18px;
        }

        .date-range-filter {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 16px;
        }
      }

      .influencer-type-wrap {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin: 10px 0;
      }
    }

    .invoice-combined-invoice-product-wrap {
      .header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
        }

        .invoice-type-wrap {
          display: flex;
          gap: 10px;
        }
      }

      .scroll-table-wrap {
        margin-top: 10px;
        max-height: 250px;
      }

      .scroll-table-wrap.show {
        max-height: 600px;
      }

      table {
        thead {
          .th-s {
            width: 85px;
            min-width: 85px;
          }
          .th-m {
            width: 127px;
            min-width: 127px;
          }
          .th-l {
            width: 220px;
            min-width: 220px;
          }
          .th-date {
            width: 118px;
            min-width: 118px;
          }
          .th-btn {
            width: 115px;
            min-width: 115px;
          }
        }
        tbody {
          .link {
            color: #6E97FF;
            text-decoration: underline;
            cursor: pointer;
          }

          .link.disable {
            color: #999999;
            cursor: unset;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.invoice-manage-brand.brand-detail {
  .manage-brand-product {
    table {
      thead {
        .th-m {
          width: 140px;
        }

        .th-btn {
          width: 110px;
        }

        .th-date {
          width: 120px;
        }
      }
      tbody {
        .link {
          color: #6E97FF;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.invoice-manage-brand.brand-detail {

}

.invoice-manage-brand.manage-brand-modal {
  .radio-wrap {
    display: flex;
    gap: 50px;
    margin-left: 1.25rem;
    .form-check-input {
      margin-top: 3px;
    }
  }
}

.invoice-manage-brand.manage-brand-link-modal {
  .radio-wrap {
    display: flex;
    gap: 50px;
    .form-check-input {
      margin-top: 3px;
    }
  }
}