// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": #6a82fb,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "gray": #b5b5b5,
  "light-gray": #cdcdcd,
  "more-light-gray": #dadada,
  "purple": #8200FF,
  "sky-blue": #a0d7e7,
  "mint": #66cb9f,
  "light-orange": #f1a460,
  "cornflower-blue": #6E97FF,
  "caribbean-green": #00E29E,
  "neon-pink": #FF6ED6,
  "black": #000000
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 287px;
